import * as React from "react"
import SparkleSmallIcon from "../img/sparkle-small.svg"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"
import ContactForm from "../widgets/ContactForm"
import Layout from "../components/layout"
import CountUp from "react-countup"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { TypeoutText } from "../widgets/MainPage"
import Stats from "../widgets/Stats"
import StyledCircularButton from "../widgets/StyledCircularButton"

export default function MainPage(props: any) {
  const { allContentfulCaseStudies } = props.data
  const data = allContentfulCaseStudies.nodes.map((e) => ({
    summary: e.summary,
  }))[0]
  const p = {
    ...props,
    title: "India’s Biggest Influencer Marketing Platform",
    description:
      "Collaborate with 1L+ Nano-Micro-Macro-Mega Influencers on Instagram & YouTube. Top brands like Nykaa, purplle, Lakme, Sugar, HUL, Vero Moda, etc. Use OPA to run Influencer Marketing Campaigns.",
  }

  React.useEffect(() => {
    setCookieFromUrl()
    // initializeAndTrack(location)
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])

  return (
    <Layout {...p}>
      <div className="mainContainer">
        <div className="container">
          <section
            className="main-banner hero-banner height-box"
            style={{ justifyContent: "center" }}
          >
            <div className="hero-banner-leftMain hero-banner-leftMain-1">
              <div className="hero-banner-leftMain">
                <h1
                  style={{
                    fontSize: "50px",
                  }}
                >
                  <span className="text-highlight-long">
                    <TypeoutText /> Influencer&nbsp;
                  </span>
                  <br />
                  <span className="text-highlight-long"> Marketing</span>&nbsp;
                  <span>at Scale</span>
                </h1>
                <p>Generate word of mouth on social media in minutes!</p>
                <StyledCircularButton
                  label="Get Started"
                  url={`https://console.opa.marketing/sign-up/`}
                  // url="https://docs.google.com/forms/d/e/1FAIpQLSfpsImg2FyT_Cg2oVgsv8S3XQvNoFqnRjLP-UytCoHkUZeoGA/viewform"
                  isBig={true}
                  isBlack={true}
                />

                <Stats data={data.summary} />
                <div className="hero-sparkle-4 rellax" data-rellax-speed="2">
                  <SparkleSmallIcon />
                </div>
                <div className="hero-arrow">
                  <StaticImage
                    placeholder="blurred"
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/hero-arrow_jiirdh.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="hero-banner-right"
              style={{
                maxWidth: 500,
                minWidth: 340,
                justifyContent: "center",
              }}
            >
              <ContactForm />
            </div>
          </section>

          <section className="case-studies" style={{ marginTop: "90px" }}>
            <h2 style={{ textAlign: "center" }}>Clientele</h2>
            <div className="case-studies-logo-list">
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client1_bn5uq3.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client2_vrszgk.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client3_vcynvz.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client4_uxtfqa.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client5_zesk7i.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client6_zymcez.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client7_pwpil7.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client8_adafz5.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client9_o1d2ka.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client10_fedmt7.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client11_xedxwg.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client12_qvoprw.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client13_bfhylx.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client14_rlme8s.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client15_vsqfec.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client16_h8yvaa.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client17_ies8vv.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client18_kfr5uc.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client19_yup4g6.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client20_sd7xgf.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client21_sh8j7s.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client22_acsmqb.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client23_re6vnx.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client24_nftfzj.png"
                  alt=""
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <br />
      <br />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulCaseStudies {
      nodes {
        summary {
          id
          key
          value
          sub
        }
      }
    }
  }
`
